video {
    object-fit: cover;
    width: 100%;
    height: 100%;
    z-index: -1;
}

.hero-container {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    box-shadow: inset 0 0 0 1000px rgba(0,0,0,0.2);
    object-fit: contain;
    text-align: center;
}

@media screen and (max-width: 786px) {
    .hero-container {
        height: 100%;
        width: 100%;
        display: flex;
        flex-direction: column;
    }
    video {
        max-width: 100%;
        height: auto;
    }
}