* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    font-family: 'PT Sans', sans-serif;
  }
  
  .home,
  .services{
    display: flex;
    height: 100vh;
    align-items: center;
    justify-content: center;
    font-size: 4vw;
  }
  
  .services {
    background-image: url('../public/images/pricelist-bg.jpg');
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    color: #fff;
    height: auto;
    font-size: 2.5vw;
    text-align: center;
  }

  .priceList img{
    width: 50%;
    border: 1px solid #070707;
    border-radius: 1%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 2%;
  }

  .gallery {
    background-image: url('../public/images/gallery-bg.jpg');
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    color: #fff;
    font-size: 4vw;
    width: auto;
  }

  .insta-wrapper {
    display: flex;
    flex-wrap: wrap; 
    justify-content: space-evenly; 
    align-items: flex-start; 
  }

  .insta-container {
    margin-top: 32px;
  }

  .regist-container {
    background-image: url('../public/images/regist-bg.jpg');
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    color: #fff;
    height: auto;
    font-size: 2vw;
  }

  .regist-word{
    background-color: rgba(0, 0, 0, 0.499);
  }

  .social-icon-links {
    display: flex;
    align-items: center;
    color: inherit;
  }

  .socialmedia-icons {
    margin-left: 2%;
    display: flex;
    flex-direction: column;
  }

  .facebook-1, .instagram-1, .phone-1, .myEmail {
    display: flex;
    align-items: center;
    padding: 2%;
  }

  .facebook-1:hover {
    color: #0f60f7;
    transition: 0.3s ease-out;
  }

  .instagram-1:hover {
    color: #fccc63;
    transition: 0.3s ease-out;
  }
  
  .fa-facebook {
    background-color: #fff;
    font-size: 6vw;
  }

  .fa-brands {
    color: darkblue;
    border-radius: 50%;
  }

  .fa-brands:hover {
    color: blue;
    transition: 0.3s ease-out;
  }

  .fa-square-instagram {
    font-size: 6vw;
    background: radial-gradient(circle at 30% 107%, #fdf497 0%, #fdf497 5%, #fd5949 45%, #d6249f 60%, #285AEB 90%);
    -webkit-background-clip: text;      
    background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  .fa-instagram:hover {
    color: rgb(220, 101, 58);
  }

  .fa-square-phone {
    font-size: 6vw;
    color: greenyellow;
  }

  .phone-1:hover, .fa-square-phone:hover {
    color: green;
    transition: 0.3s ease-out;
  }

  h4{
    text-align: center;
  }

  .content{
    margin-left: 2%;
    transition: 0.3s ease-out;
  }

  .icons {
    margin-left: 5%;
  }

  .regist-word > h1 {
    margin-bottom: 2%;
  }

  .myEmail {
    font-size: 6vw;
  }

  .regist-form,
  .signin-form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    color: #fff;
  }

  .regist-input,
  .signin-input {
    padding: 8px 25px;
    border-radius: 5px;
    margin: 10px;
    text-align: center;
    outline: none;
    border: none;
    font-size: 18px;
    margin-bottom: 8px;
    border: 1px solid #070707
  }

  .regist-input::placeholder,
  .signin-input::placeholder {
    color: black;
    text-align: center;
  }

  .regist-btn,
  .signin-btn {
    padding: 12px 26px;
    border-radius: 2px;
    border: 1px solid black;
    cursor: pointer;
    font-size: 1.5rem;
    transition: all 0.3s ease-out;
    margin-top: 5px;
  }

  .regist-btn:hover,
  .signin-btn:hover {
    background-color: #E6C068;
    transition: 250ms;
  }

  @media screen and (max-width: 960px) {
    .services{
      height: 90vh;
    }
    .priceList img{
      width: 74%;
    }
    .insta-wrapper {
      flex-direction: column;
      align-items: center;
    }
    .instagram-post {
      max-width: 100%;
    }
    .services {
      background-image: url('../public/images/pricelist-mobile-bg.jpg');
    }
  }